import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import ServicePage from 'templates/servicePage';
import ServiceRows from 'components/layout/serviceRows';

const ClubTeamManagement = ({ data }) => {
  const {
    markdownRemark: { frontmatter: { 
      hero, main,
    } },
  } = data;

  return (
    <ServicePage
      data={data}
      heroImage={(
        <Img fluid={hero.image.src.childImageSharp.fluid} alt={hero.image.alt} />
      )}
    >
      <ServiceRows data={main} />
    </ServicePage>
  );
};

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      ...LayoutFragment
      ...ServicePageFragment
      ...ServiceHeroFragment
      ...ServiceRowOneFragment
      ...ServiceRowTwoFragment
    }
  }
`;

ClubTeamManagement.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ClubTeamManagement;
